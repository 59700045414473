import React, { useState, useEffect } from 'react';
import axiosInstance from '../Admin/axiosInstance';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Container, TextField, Select, MenuItem, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Alert, CircularProgress, TablePagination, Tab } from '@mui/material';
import { Schedule } from '@mui/icons-material';
import './../../App.css'; 
import logo from '../../images/dream.PNG';

const WaybillComponent = () => {
  const [carriedBy, setCarriedBy] = useState('');
  const [route, setRoute] = useState('1'); // Default to Nairobi-Wajir
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [busOptions, setBusOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [selectedBus, setSelectedBus] = useState('');
  const [selectedDriver, setSelectedDriver] = useState('');
  const [selectedBusDetails, setSelectedBusDetails] = useState(null);
  const [selectedDriverDetails, setSelectedDriverDetails] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Fetch waybill data
  const fetchWaybillData = async () => {
    setLoading(true);
    setError('');
    try {
      // Include carriedBy in the API endpoint
      const response = await axiosInstance.get(`/parcel/waybill/${route}/${carriedBy}`);
      if (response.data.length === 0) {
        setError('No data found for the selected date and route.');
        setData([]);
      } else {
        setData(response.data);
      }
    } catch (error) {
      setError('Error fetching waybill data. Please try again.');
      console.error('Error fetching waybill data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch buses and drivers
  useEffect(() => {
    const fetchDrivers = async () => {
      setFetchingData(true);
      try {
        const response = await axiosInstance.get('/users/drivers');
        setDriverOptions(response.data); // Assuming response.data is an array of drivers
      } catch (error) {
        console.error('Error fetching drivers:', error);
      } finally {
        setFetchingData(false);
      }
    };

    //fetchBuses();
    fetchDrivers();
  }, []);

  // Handle bus and driver selection
  const handleBusChange = (event) => {
    const busReg = event.target.value;
    setSelectedBus(busReg);
    const bus = busOptions.find(b => b.busRegistration === busReg);
    setSelectedBusDetails(bus);
  };

  const handleDriverChange = (event) => {
    const driverId = event.target.value;
    setSelectedDriver(driverId);
    const driver = driverOptions.find(d => d.idNumber === driverId);
    setSelectedDriverDetails(driver);
  };

  const generatePDF = () => {
    if (!data.length || !selectedDriverDetails) return;
  
    // Create a new jsPDF instance with landscape orientation
    const doc = new jsPDF('landscape');
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
  
    const logoWidth = 30;
    const logoX = (pageWidth - logoWidth) / 2;
  
    // Add logo at the top center
    doc.addImage(logo, 'PNG', logoX, 10, logoWidth, 20);
  
    // Add the company header below the logo
    doc.setFontSize(16);
    doc.setFont('Helvetica', 'normal');
    doc.text('Dream C Ltd', 148.5, 40, { align: 'center' });
    doc.setFontSize(16);
    doc.text('P.O.BOX 94-70200 Wajir, Kenya', 148.5, 48, { align: 'center' });
    doc.text('Office Number-Nairobi: +254 769200300', 148.5, 55, { align: 'center' });
    doc.text('Office Number-Wajir: +254 769200400', 148.5, 61, { align: 'center' });
  
    // Add dispatch information
    doc.setFontSize(13);
    const startXLeft = 14;
    const startY = 60;
    doc.text(`Carried By: ${carriedBy}`, startXLeft, startY + 8);
  
    // Add the generation date and time
    const dateGenerated = new Date().toLocaleDateString();
    const timeGenerated = new Date().toLocaleTimeString();
    doc.text(`Date Generated: ${dateGenerated} ${timeGenerated}`, startXLeft, startY + 15);
  
    // Add waybill title in bold
    doc.setFontSize(36);
    doc.setFont('Helvetica', 'bold');
    doc.text('Dispatch Waybill', 148.5, startY + 35, { align: 'center' });
  
    // Add table with waybill data
    doc.autoTable({
      startY: startY + 40,
      margin: { left: 14, right: 14 },
      head: [['Origin', 'Destination', 'No of items', 'Total Weight', 'Remarks']],
      body: data.map(item => [
        item.source,
        item.destination,
        item.itemsCount.reduce((sum, count) => sum + count, 0),
        item.totalWeight,
      ]),
      headStyles: { fillColor: [0, 0, 255] },
      styles: { cellWidth: 'wrap' },
      didDrawPage: (data) => {
        // Check if we are on the last page and position the footer accordingly
        const totalPages = doc.internal.getNumberOfPages();
        if (data.pageCount === totalPages) {
          // Footer position on the last page
          const infoStartY = pageHeight - 40;
          const startXRight = pageWidth - 80;
  
          // Driver Details section
          doc.setFontSize(13);
          doc.setFont('Helvetica', 'normal');
          doc.text('Driver Details:', startXRight, infoStartY);
          doc.text(`Name: ${selectedDriverDetails.firstName} ${selectedDriverDetails.lastName}`, startXRight, infoStartY + 6);
          doc.text('Driver Signature: _______________________', startXRight, infoStartY + 15);
  
          // Generated By section for logged-in user details
          const username = localStorage.getItem('username');
          doc.text(`Generated By: ${username}`, startXLeft, infoStartY);
          doc.text('Signature: _______________________', startXLeft, infoStartY + 8);
          doc.text('Comments:', startXLeft, infoStartY + 16);
          doc.text('_______________________________', startXLeft, infoStartY + 24);
          doc.text('_______________________________', startXLeft, infoStartY + 32);
        }
      },
    });
  
    // Save the PDF
    doc.save('waybill.pdf');
  };
  

  // Handle pagination change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
      <Container style={{ backgroundColor: '#f5f5f5' }}>
      <Typography variant="h4" gutterBottom align="center">
        Parcel Waybill
      </Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center" marginBottom={2}>
      <Grid item xs={12} sm={6} md={4}>
          <Select
            fullWidth
            value={carriedBy || ""} 
            onChange={(e) => setCarriedBy(e.target.value)}
            displayEmpty
            sx={{
              maxWidth: 300,
              width: '100%',  
            }}
          >
            <MenuItem value="" disabled>Select Carrier</MenuItem>  
            <MenuItem value="bus">Bus</MenuItem>
            <MenuItem value="lorry">Lorry</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Select
            fullWidth
            value={route}
            onChange={(e) => setRoute(e.target.value)}
            sx={{ maxWidth: 300 }}
            displayEmpty
          >
            <MenuItem value="" disabled>Select Carrier</MenuItem>
            <MenuItem value="1">Nairobi-Wajir</MenuItem>
            <MenuItem value="2">Wajir-Nairobi</MenuItem>
          </Select>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
          <Select
            fullWidth
            value={selectedDriver}
            onChange={handleDriverChange}
            displayEmpty
            sx={{ maxWidth: 300 }}
          >
            <MenuItem value="" disabled>Select Driver</MenuItem>
            {driverOptions.map((driver) => (
              <MenuItem key={driver.idNumber} value={driver.idNumber}>
                {driver.firstName} {driver.lastName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" marginBottom={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Schedule />}
          onClick={fetchWaybillData}
          disabled={loading || fetchingData}
        >
          Fetch Data
        </Button>
      </Grid>
      {loading && (
        <Typography variant="body1" align="center" color="textSecondary">
          Loading data...
        </Typography>
      )}
      {error && (
        <Alert severity="error" align="center">
          {error}
        </Alert>
      )}
      {data.length > 0 && (
        <>
          <TableContainer component={Paper} style={{ marginTop: 20 }}>
            <Table>
              <TableHead style={{ backgroundColor: '#1976d2' }}> 
                <TableRow>
                  <TableCell sx={{ color: '#fff' }}>Origin</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Destination</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Parcel Count</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Weight</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Item Count</TableCell>
                  {/* <TableCell sx={{ color: '#fff' }}>Content</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Package</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Tracking Codes</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.source}</TableCell>
                    <TableCell>{item.destination}</TableCell>
                    <TableCell>
                      {item.parcelCount.length}
                      {/* {item.parcelCount.map((item,index)=>(
                        <div key={index}>{item}</div>
                      ))} */}
                    </TableCell>
                    <TableCell>{item.totalWeight}</TableCell>
                    <TableCell>
                      {item.itemsCount.reduce((sum, count) => sum + count, 0)}
                    </TableCell>
                    {/* <TableCell>
                      {item.parcelContent.map((item,index)=>(
                        <div key={index}>{item}</div>
                      ))}
                    </TableCell>
                    <TableCell>
                      {item.packageType.map((item, index)=>(
                        <div key={index}>{item}</div>
                      ))}
                    </TableCell>
                    <TableCell>
                      {item.trackingCodes.map((item,index)=>(
                        <div key={index}>{item}</div>
                      ))}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
          <Grid container justifyContent="center" marginTop={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={generatePDF}
              disabled={loading || fetchingData}
            >
              Generate PDF
            </Button>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default WaybillComponent;
