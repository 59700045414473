import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio, 
  MenuItem, 
  Select
} from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axiosInstance from '../Admin/axiosInstance';
import logo from '../../images/dream.PNG';

const Corporate = () => {
  const [corporates, setCorporates] = useState([]);
  const [newCorporateName, setNewCorporateName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedCorporate, setSelectedCorporate] = useState('');
  const [selectedCorporateId, setSelectedCorporateId] = useState('');
  const [invoices, setInvoices] = useState([]); 
  const [payDialogOpen, setPayDialogOpen] = useState(false);
  const [payAmount, setPayAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [amountDue, setAmountDue] = useState(0);
 
  // Fetch all corporates
  useEffect(() => {
    fetchCorporates();
  }, []);

  const fetchCorporates = async () => {
    try {
      const response = await axiosInstance.get('/customer/findall');
      console.log('Fetched corporates:', response.data);
      setCorporates(response.data);
    } catch (error) {
      console.error('Error fetching corporates:', error);
    }
  };

  const handleAddCorporate = async () => {
    if (!newCorporateName || !email || !phoneNumber || !contactPerson) return;
  
    try {
      await axiosInstance.post('/customer/add', {
        name: newCorporateName,
        email,
        phoneNumber,
        contactPerson
      });
      // Reset fields after successful addition
      setNewCorporateName('');
      setEmail('');
      setPhoneNumber('');
      setContactPerson('');
      fetchCorporates(); 
      setOpenDialog(false); 
    } catch (error) {
      console.error('Error adding corporate:', error);
    }
  };
  

  const handleFetchInvoices = async () => {
    if (!selectedCorporateId || !startDate || !endDate) {
      alert('Please select a corporate name and specify a date range.');
      return;
    }

    try {
      const invoiceData = await axiosInstance.get(`/parcel/invoice/${selectedCorporate}/${startDate}/${endDate}`);
      setInvoices(invoiceData.data); // Store invoice data in state
      console.log('Invoices:', invoiceData.data);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  // Calculate the total amount for the invoices
  const totalAmount = invoices.reduce((total, invoice) => total + (invoice.cashAmount || 0), 0);
  const amountPaid = totalAmount - amountDue;

  const fetchCorporateInfo = async (corporateId) => {
    try {
      const response = await axiosInstance.get(`/customer/info/${corporateId}`);
      setAmountDue(response.data.amountDue);
    } catch (error) {
      console.error('Error fetching corporate info:', error);
    }
  };

  const handleOpenPayDialog = () => {
    if (!selectedCorporate) {
      alert('Please select a corporate to pay.');
      return;
    }
    setPayDialogOpen(true);
  };

  const handlePay = async () => {
    if (!payAmount || payAmount <= 0 || payAmount > totalAmount) {
      alert('Please enter a valid amount.');
      return;
    }

    try {
      await axiosInstance.post(`/customer/pay/${selectedCorporate}/${payAmount}`);
      alert(`Successfully paid ${payAmount} for ${selectedCorporate}`);
      setPayDialogOpen(false);
      setPayAmount('');
      handleFetchInvoices(); 
    } catch (error) {
      console.error('Error processing payment:', error);
      alert('Payment failed. Please try again.');
    }
  };

  const downloadPDF = () => {
    const doc = new jsPDF('landscape');
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const logoWidth = 30;
    const logoX = (pageWidth - logoWidth) / 2;
    doc.addImage(logo, 'PNG', logoX, 10, logoWidth, 20);

    // Organization Name and Address
    const organizationName = 'Dream C Ltd';
    doc.setFontSize(16);
    doc.setFont('Helvetica', 'bold');
    doc.text(organizationName, 148.5, 35, { align: 'center' });

    const organizationAddress = 'P.O.BOX 94-70200 Wajir, Kenya';
    const organizationPhone = 'Office Number-Nairobi: +254 769200300';
    const organizationWajir = 'Office Number-Wajir: +254 769200400';
    doc.setFontSize(14);
    doc.setFont('Helvetica', 'normal');
    doc.text(organizationAddress, 148.5, 42, { align: 'center' });
    doc.text(organizationPhone, 148.5, 47, { align: 'center' });
    doc.text(organizationWajir, 148.5, 53, { align: 'center' });

    // Add the word "Invoice" with larger, bold font below the logo
    doc.setFontSize(32);
    doc.setFont('Helvetica', 'bold');
    doc.text('Invoice', 148.5, 65, { align: 'center' });
    doc.setFontSize(16);
    doc.setFont('Helvetica', 'bold');
    doc.text(`Customer Name: ${selectedCorporate}`, 148.5, 73, { align: 'center' });

    // Table Headers
    const tableColumn = [
        'Source', 'Destination', 'Sender Name', 'Receiver Name',
        'Sender Phone', 'Receiver Phone', 'Book Date', 'Weight',
        'Tracking Code', 'Items Count', 'Package Type', 'Amount'
    ];
    const tableRows = [];

    let totalAmount = 0; // Variable to hold the total amount

    // Populate table rows with invoice data
    invoices.forEach((invoice) => {
        const invoiceData = [
            invoice.source,
            invoice.destination,
            invoice.senderName,
            invoice.receiverName,
            invoice.senderPhone,
            invoice.receiverPhone,
            invoice.bookDate,
            invoice.parcelWeight,
            invoice.trackingCode,
            invoice.itemsCount,
            invoice.packageType,
            invoice.cashAmount,
        ];
        totalAmount += parseFloat(invoice.cashAmount);
        tableRows.push(invoiceData);
    });
    
    const spacerRow = ['', '', '', '', '', '', '', '', '', '', '', ''];
    tableRows.push(spacerRow);
    // Add a row for the total amount
    const totalRow = ['Total Amount', '', '', '', '', '', '', '', '', '', '', totalAmount.toFixed(2)];
    tableRows.push(totalRow);

    const amountPaidRow = ['Amount Paid', '', '', '', '', '', '', '', '', '', '', amountPaid.toFixed(2)];
    const amountDueRow = ['Amount Due', '', '', '', '', '', '', '', '', '', '', amountDue.toFixed(2)];
    tableRows.push(amountPaidRow, amountDueRow);

    const footerHeight = 50; 
    doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 78,
        styles: { fontSize: 10 },
        bodyStyles: { fontStyle: 'bold' },
        margin: { bottom: footerHeight }, 
    });

    // Additional Fields at the Bottom
    const username = localStorage.getItem('username');
    const dateGenerated = new Date().toLocaleDateString();

    // Generated By Section
    doc.setFontSize(12);
    doc.setFont('Helvetica', 'normal');
    doc.text(`Generated By: ${username}`, 14, pageHeight - footerHeight + 10);
    doc.text(`Date Generated: ${dateGenerated}`, 14, pageHeight - footerHeight + 20);

    // Comments Section
    doc.text("Comments:", 80, pageHeight - footerHeight + 10);
    doc.line(110, pageHeight - footerHeight + 10, pageWidth - 20, pageHeight - footerHeight + 10);
    doc.text("Authorized Signature:", 14, pageHeight - footerHeight + 30);
    doc.line(60, pageHeight - footerHeight + 30, 140, pageHeight - footerHeight + 30);
    doc.text("Organization Stamp:", 160, pageHeight - footerHeight + 30);
    doc.line(210, pageHeight - footerHeight + 30, 290, pageHeight - footerHeight + 30);

    doc.save(`${selectedCorporate}_Invoices.pdf`);
};

return (
  <Box sx={{ p: 2 }}>
    <Typography variant="h4" gutterBottom>
      Corporate Management
    </Typography>
    <Button variant="contained" onClick={() => setOpenDialog(true)}>
      Add Corporate
    </Button>

    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>Add Corporate</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Corporate Name"
          type="text"
          fullWidth
          variant="outlined"
          value={newCorporateName}
          onChange={(e) => setNewCorporateName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Phone Number"
          type="tel"
          fullWidth
          variant="outlined"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Contact Person"
          type="text"
          fullWidth
          variant="outlined"
          value={contactPerson}
          onChange={(e) => setContactPerson(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
        <Button onClick={handleAddCorporate}>Add</Button>
      </DialogActions>
    </Dialog>


    <Box sx={{ my: 2 }}>
      <TextField
        label="Start Date"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <TextField
        label="End Date"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />
    </Box>

    <Box sx={{ mb: 2 }}>
      <Button variant="outlined" onClick={handleFetchInvoices}>
        Fetch Invoices
      </Button>
      <Button variant="outlined" onClick={downloadPDF} disabled={!invoices.length}>
        Download PDF
      </Button>
    </Box>

    <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Select</TableCell>
          <TableCell>Corporate Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Phone Number</TableCell>
          <TableCell>Contact Person</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {corporates.map((corporate) => (
          <TableRow key={corporate.id}>
            <TableCell>
              <Radio
                checked={selectedCorporateId === corporate.id}
                onChange={() => {
                  setSelectedCorporateId(corporate.id);
                  fetchCorporateInfo(corporate.id); 
                  setSelectedCorporate(corporate.name);
                }}
              />
            </TableCell>
            {/* <TableCell>
              <Radio
                checked={selectedCorporate === corporate.name}
                onChange={() => setSelectedCorporate(corporate.name)}
              />
            </TableCell> */}
            <TableCell>{corporate.name}</TableCell>
            <TableCell>{corporate.email}</TableCell>
            <TableCell>{corporate.phoneNumber}</TableCell>
            <TableCell>{corporate.contactPerson}</TableCell>
            <TableCell>
              <Button
                variant="outlined"
                onClick={handleOpenPayDialog}
                disabled={selectedCorporate !== corporate.name || totalAmount === 0}
              >
                Pay
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>

    {invoices.length > 0 && (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">Invoice List for {selectedCorporate}:</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Source</TableCell>
                <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Destination</TableCell>
                <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Sender Name</TableCell>
                <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Receiver Name</TableCell>
                <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((invoice) => (
                <TableRow key={invoice.id}>
                  <TableCell>{invoice.source}</TableCell>
                  <TableCell>{invoice.destination}</TableCell>
                  <TableCell>{invoice.senderName}</TableCell>
                  <TableCell>{invoice.receiverName}</TableCell>
                  <TableCell>{invoice.cashAmount}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={4} align="right">
                  <strong>Amount Owed</strong>
                </TableCell>
                <TableCell>
                  <strong>{amountDue}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="right">
                  <strong>Amount Paid</strong>
                </TableCell>
                <TableCell>
                  <strong>{amountPaid}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="right">
                  <strong>Total Amount</strong>
                </TableCell>
                <TableCell>
                  <strong>{totalAmount}</strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )}
    {/* Pay Dialog */}
    <Dialog open={payDialogOpen} onClose={() => setPayDialogOpen(false)}>
        <DialogTitle>Pay Amount</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Total Amount Owed: <strong>{amountDue}</strong>
          </Typography>
          <TextField
            label="Enter Amount to Pay"
            type="number"
            fullWidth
            value={payAmount}
            onChange={(e) => setPayAmount(e.target.value)}
          />
           <Select
                fullWidth
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                displayEmpty
                sx={{ mt: 2 }}
              >
                <MenuItem value="" disabled>
                  Select Payment Method
                </MenuItem> 
                <MenuItem value="mpesa">MPESA</MenuItem>
                <MenuItem value="cash">Cash</MenuItem>
                <MenuItem value="cheque">Cheque</MenuItem>
                <MenuItem value="bank">Bank Transfer</MenuItem>
              </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPayDialogOpen(false)}>Cancel</Button>
          <Button onClick={handlePay}>Pay</Button>
        </DialogActions>
      </Dialog>
  </Box>
);
};

export default Corporate;